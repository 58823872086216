<template>
	<div>
		<div class="container my-md-5">
			<div class="row">
				<div v-if="!noside" class="col-md-3 d-none d-md-block">
					<side-nav :links="links" :active-route-name="activeRouteName" />
				</div>
				<div class="page-content p-0 px-md-3" :class="noside ? 'col-12' : 'col-md-9'">
					<slot />
					<side-nav :links="links" :active-route-name="activeRouteName" mobile />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import SideNav from '../components/SideNav.vue';
import { createLinks } from '@/router/nav-links.js'

export default {
	props: {
		noside: Boolean
	},
	components: {
		SideNav
	},
	data() {
		return {
			activeRouteName: this.$router.history.current.name,
            links: createLinks()
		};
	}
};
</script>

<style></style>
